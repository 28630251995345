* {
  margin: 0;
  padding: 0;
  background-color: black;
  height: 100%

}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.body {
  margin: 0;
  padding: 0;
/*   background-color: black;
 */  /* overflow: hidden; */
  height: 100%

}

.grid-container{
  display:grid;
  grid-template-rows: 1 1;
  grid-template-columns: 1;

}

.grid-item-header{
  justify-items: center;
  display: grid;
}



.htmlText{
  color: yellow;
  font-size: small;
  font-weight: bold;
  font-family:'Roboto'
}
.htmlTextSmall{
  color: green;
  font-size:x-small;
 font-family:'Roboto'
} 



.content{

  background-color: green;
  opacity: 0.75;
  padding-right: .5rem; 
  padding-left: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-radius: 0.5rem;
    max-height: 8rem;
    display: flex;
 flex-direction: column

  
}


.textContent{
  background-color: green;

  color: white;
font-size: small;
/* display: flex;
 flex-direction: column;
 flex-wrap: wrap */
}

.textContentBold{
  background-color: green;

  color: white;
  font-weight: bold;
  font-size: large;
/*   display: flex;
 flex-direction: column;
 flex-wrap: wrap */
}

.textContentYellow{
  background-color: green;

  color: yellow;
font-size: small;
/* display: flex;
 flex-direction: column;
 flex-wrap: wrap */
}


@media only screen and (max-width: 300px){
  .content{

    background-color: green;
    opacity: 0.75;
    padding-right: 1rem; 
    padding-left: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
  }



}